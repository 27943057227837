import { Routes, Route } from "react-router-dom";
import Party from "pages/party";
import { useAppData } from "hooks/useAppData";
import { ClientAdminTab, InteractiveTab } from "components/didoDemo/RightContent";
import FundsSearch from "pages/funds/Search";
import Funds from "pages/funds";
import { PARTY, ACCESS_PERMISSIONS } from "constants/index";
import { HeaderType } from "types";
import Header from "components/header/Header";
import { Main } from "styles/App.styled";
import { FundsSearchResults } from "pages/funds/FundsSearchResults";
import FundHeader from "components/header/FundHeader";
import Home from "../pages/home/Home";
import Protected from "./Protected";
import { SearchResults } from "../pages/searchResults/SearchResults";
import SearchPage from "../pages/search/Search";
import Alert from "../pages/alert/Alert";
import DiDoDemo from "../pages/didoDemo";
import AdminRoom from "../pages/admin";
import ClientRoom from "../pages/client";
import AliceRequests from "../pages/admin/AliceRequests";
import ClientAliceRequests from "../pages/client/AliceRequests";
import Report from "../pages/client/Report";
import Connect from "../pages/client/Connect";
import EntityLink from "../pages/admin/EntityLink";
import GetSearch from "../pages/admin/Search";
import Dashboard from "../pages/admin/Dashboard";
import Settings from "../pages/admin/Settings";
import FundPartyManagerDashboard from "pages/fundPartyManager/Dashboard";
import Onboarding from "pages/fundPartyManager/Onboarding";
import ActiveCcIds from "pages/fundPartyManager/ActiveCcIds";
import MasterBuylist from "pages/fundPartyManager/MasterBuylist";
import MyView from "pages/fundPartyManager/MyView";
import HubwiseClientTemplateRequest from "pages/fundPartyManager/ClientTemplateRequest";
import FundPartyFundManager from "pages/fundPartyManager/FundManager";
import {Auth0Routes} from "./Auth0Routes";
import NoAccessComponent from "components/noAccess/NoAccessComponent";
import NoData from "components/noData/NoData";
import FundDetailsConfirmation from "pages/fundDetailsConfirmation/FundDetailsConfirmation";
import EsgQuestionnaire from "pages/esg/EsgQuestionnaire";
import HubwiseAgentCodes from "pages/fundPartyManager/AgentCodes";
import ClientManager from "pages/fundPartyManager/ClientManager";
import NewPartyHeader from "components/header/NewPartyHeader";
import ActiveRequests from "pages/fundPartyManager/instrumentsManager/ActiveRequests";
import ActiveFunds from "pages/fundPartyManager/instrumentsManager/ActiveFunds";
import UserGuides from "pages/fundPartyManager/UserGuides";
import ReportsGenerator from "pages/fundPartyManager/ReportsGenerator";
import AllfundsRequests from "pages/fundPartyManager/AllfundsRequests";

function AppRoutes() {
  const { isUserPartOfOrganization, isUserAuthenticated, isT360Email, isUserPermission } = useAppData();

  return (
    <Main>
      <Header
        headerStyle={HeaderType.DEFAULT}
        isT360Email={isT360Email!}
        isUserAuthenticated={isUserAuthenticated}
      />

      <Routes> 
        <Route element={<Auth0Routes />}>
          <Route path="/" element={ <Home /> } />

          {/* Party Manager Module routes */}
          <Route path="" element={<Protected no_access={!isUserPermission([ACCESS_PERMISSIONS.PARTY_MANAGER])} />} >
              <Route path="/search" element={<SearchPage />} />
              <Route path="/search-results/:targetEntityId" element={<SearchResults />} />
              <Route path="/alert" element={<Alert />} />
              <Route path="/sipp" element={<NoData />} />
              <Route path="/party/:id" element={<Party />} />
          </Route>

          {/* Instruments Manager Module routes */}

              <Route path="/fpm">
                <Route path="" element={< Protected no_access={!isUserPermission([ACCESS_PERMISSIONS.INVESTORS_MANAGER_ACCESS])}/>}>
                  <Route path="investments-manager">
                    <Route path="dashboard" element={<FundPartyManagerDashboard />}>
                      <Route path="onboarding" element={<Onboarding />} />
                      <Route path="alice-requests" element={<AliceRequests />}/>
                      <Route path="fund-manager" element={<FundPartyFundManager />} />
                      <Route path="allfunds-requests" element={<AllfundsRequests />} />
                      <Route path="buylist" element={<MasterBuylist />} />
                      <Route path="instrument-universe" element={<NoData />} />
                      <Route path="glossary" element={<NoData />} />
                      {/* <Route path="active-ccids" element={<ActiveCcIds />} /> */}
                      <Route path="c-manager" element={<ClientManager />} />
                      <Route path="legal" element={<NoData />} />
                      <Route path="reports" element={<ReportsGenerator />} />
                      <Route path="my-view" element={<MyView />} />
                      <Route path="ssi" element={<HubwiseAgentCodes />} />
                      <Route path="user-guides" element={<UserGuides />} />
                    </Route>
                    <Route path="sp">
                      <Route path="crt/:id" element={<HubwiseClientTemplateRequest />} />
                    </Route>
                  </Route>
                </Route>
                <Route path="" element={< Protected no_access={!isUserPermission([ACCESS_PERMISSIONS.INSTRUMENTS_MANAGER_ACCESS])}/>}>
                  <Route path="instruments-manager">
                    <Route path="dashboard" element={<FundPartyManagerDashboard />}>
                      <Route path="active-requests" element={<ActiveRequests />}/>
                      <Route path="active-funds-clients" element={<ActiveFunds />}/>
                    </Route>
                    <Route path="sp">
                      <Route path="fdr" element={<FundDetailsConfirmation />}/>
                    </Route>
                  </Route> 
                </Route>
              </Route>
              <Route path="/funds/:id" element={<Funds />} />

          {/* client room */}
          <Route path="" element={<Protected no_access={!isUserPermission([ACCESS_PERMISSIONS.CLIENT_CONSOLE_ACCESS])} />}>
            <Route path="/client" element={<ClientRoom />}>
              <Route path="alice-requests" element={<ClientAliceRequests />} />
              <Route path="connect" element={<Connect />} />
              <Route path="report" element={<Report />} />
              <Route path="settings" element={<Settings />} />
            </Route>
          </Route>

          {/* admin room */}
          <Route path="" element={<Protected no_access={!isUserPermission([ACCESS_PERMISSIONS.ADMIN_CONSOLE_ACCESS])} />}>
            <Route path="/admin" element={<AdminRoom />}>
              <Route path="alice-requests" element={<AliceRequests />} />
              <Route path="entity-link" element={<EntityLink />} />
              <Route path="get-search" element={<GetSearch />} />
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="settings" element={<Settings />} />
            </Route>
          </Route>

          {/* funds routes */}
          <Route path="" element={<Protected no_access={!isUserPermission([ACCESS_PERMISSIONS.PARTY_MANAGER])} />} >
            <Route path="/funds/search" element={<FundsSearch />} />
            <Route path="/funds/search-results/:targetEntityId" element={<FundsSearchResults />} />
          </Route>

          {/* didodemo room */}
          <Route path="" element={<Protected no_access={!isUserPermission([ACCESS_PERMISSIONS.DIDO_DEMO])} />}>
            <Route path="/dido-demo" element={<DiDoDemo />}>
              <Route path="interactive" element={<InteractiveTab />} />
              <Route path="client-admin" element={<ClientAdminTab />} />
              <Route path="settings" element={<Settings />} />
            </Route>
          </Route>

          <Route path="" element={<Protected no_access={!isUserPermission([ACCESS_PERMISSIONS.PARTY_MANAGER])} />}>
            <Route path="/esg" element={<EsgQuestionnaire />}/>
          </Route>

          {/* no routes match fallback */}
          <Route path="*" element={<NoAccessComponent />}/>
        </Route>
      </Routes>
    </Main>
  );
}

export default AppRoutes;
